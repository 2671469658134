.navbar-nav #asset-onboarding,
#client_name_tooltip,
#legal_entity_tooltip,
brain-global-catalog-filter-tabs
 {
    display: none !important;
}

.dbg-theme {
    background: #fff !important;

    aihub-loader {
        .loader-overlay .loader:before {
            background-color: #cfcfcf !important;
        }
    }

    .navbar {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background: #fff;

        .nav-item:hover {
            background: transparent;
        }

        .header-logo {
            height: 40px !important;
            width: 100% !important;
            margin-left: 25px;
        }

        li a {
            color: #000 !important;
        }
    }

    .static-nav-item {
        color: #000 !important;
    }

    .shared-header .mat-menu-trigger.active {
        background: transparent !important;
    }

    .bg-black,
    .catalogue__header,
    .brain-asset-detail .header {
        background: #fff !important;
    }

    .autocomplete-search-wrapper {
        border: 1px solid #000099 !important;
    }

    brain-global-header {
        .tile {
            box-shadow: none;
        }

        .asset-container {
            max-height: 350px;
            padding: 20px 5% !important;
        }

        .catalog_image img {
            max-height: 3.5rem !important;
        }
    }

    .catalogue__header--title,
    .catalogue__header--subtitle,
    .brain-asset-detail .header-title,
    .brain-asset-detail .header-subtitle,
    .brain-asset-detail .header aihub-breadcrumb .list.breadcrumb-list .breadcrumb-list--item a.router-link-active {
        color: #000 !important;

        p>img {
            display: none;
        }
    }

    .catalogue__header {
        padding-bottom: 0px !important;
    }

    .catalog_image {
        margin-top: 3rem;
    }

    .border-green {
        border-bottom: 1px solid #c7cacc;
    }

    .deloitteai--header__list .counter {
        background-color: transparent !important;
        border: 1px solid #000 !important;
    }

    .btn.btn-primary:disabled,
    .btn.btn-primary {
        color: #fff !important;
        background-color: #009;

    }

    asset-owner--menu li .label:before,
    .deloitteai__header--usermenu .user {
        color: #fff !important;
        background-color: #009 !important;
    }

    .aihub-tooltip mat-icon,
    .badge.success,
    .btn.btn-secondary,
    brain-global-catalog-page a,
    deloitteai-contact-banner a,
    .deloitteai--header__list .counter,
    .available-items .mat-chip {
        color: #000099 !important;
    }

    footer {
        background-color: #fff;
        border-top: 1px solid #ccc;

        a {
            font-weight: normal;
        }
    }

    .deloitteai__header--usermenu ul {
        background: #fff;
    }

    .hamburger-logo {
        width: 30px !important;
        margin-right: 2rem;
        height: 20px !important;
    }

    .available-items .mat-chip {
        border-color: #000099 !important;
    }
}
